<template>
    
    <div class="dropbox">
        <input type="file" multiple @change="filesChange($event); fileCount = $event.target.files.length"
            accept="image/*" class="input-file">
            <p v-if="isInitial">
                {{$t('Drag your file(s) here or click to browse')}}
            </p>
        </div>
    
</template>

<script>
    
    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
    
    export default {
        props: {
            maxSize: {
                type: Number,
                default: 5,
                required: false,
            },
            accept: {
                type: String,
                default: "image/*",
            },
            value: {
                type: [String, Array],
                default: null,
                required: true
            }
        },
        data() {
            return {
                files: [],
                uploadError: null,
                currentStatus: null
            }
        },
        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            }
        },
        mounted() {
            this.reset();
        },
        
        methods: {
            reset() {
                // reset form to initial state
                this.currentStatus = STATUS_INITIAL;
                this.uploadedFiles = [];
                this.uploadError = null;
            },
            
            filesChange(event) {
                if (event.target.files && event.target.files[0]) {
                    
                    event.target.files.forEach((file) => {
                        let fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
                            fileExtention = file.name.split(".").pop(),
                            fileName = file.name.split(".").shift(),
                            isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention),
                            type = file.type;
                        
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        
                        reader.onload = r =>{
                            console.log(r);
                            let fileDetails = {
                                hr_size: fileSize,
                                extension: fileExtention,
                                name: fileName,
                                is_image: isImage,
                                type: type,
                                content: r.target.result
                            };
                            
                            this.files.push(fileDetails);
                        };
                        
                        reader.onerror = e => {
                            console.log(e);
                            alert('Unable to upload file');
                            return;
                        };
                        
                    });
                
                }
                
                this.$emit('input', this.files)
            }
        }
    }
    
</script>

<style lang="scss">
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 100px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top:0;
    left:0;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 10px 20px;
    margin: 0;
  }
</style>

<template>
    <section class="rma-page" v-if="!returnOrder">
        <b-modal
            id="rma-terms"
            class="rma-modal-inner"
            centered
            hide-footer
            content-class="defaul-modal"
            size="xl"
            >
            <template #modal-title class="bold"
                >{{ $t("rma_terms_modal_header") }}
            </template>
            <div class="modal-text d-block border-bottom" v-html="settings.terms_text"></div>
            <div class="modal-btn-box pt-20">
                <b-button
                    class="btn-primary btn-outline"
                    block
                    @click="$bvModal.hide('rma-terms')"
                    >{{ $t("close") }}</b-button
                    >
            </div>
        </b-modal>
        <b-container>
            <div class="rma-container"  v-if="!this.order && !this.loading && !this.errors">
                <div class="alert alert-danger">
                    <h4>{{$t('Oops')}}</h4>
                    <div>{{$t('Something went wrong with your request.')}}</div>
                </div>
            </div>
            
            <div class="messages"  v-if="messages && messages.length">
                <div class="alert alert-danger" v-for="(message, index) in dangerMessages" :key="index">
                    <h4>{{$t('Oops')}}</h4>
                    <div>{{$t(message.message)}}</div>
                </div>
                <div class="alert alert-success" v-for="(message, index) in successMessages" :key="index">
                    <div>{{$t('message.message')}}</div>
                </div>
            </div>
            
            <div class="text-center loader-wrapper" v-show="loading">
                <div class="pro-loader">
                    <div class="reverse-spinner"></div>
                </div>
            </div>
            
            <div class="order-container" v-if="order">
                <h2>{{$t('Request return for order #{orderNumber}', {orderNumber: order.number})}}</h2>
                <cmsBlockSimple identifier="rma_new_request_intro" />
                
                <div class="return-items-form mt-5">
                    
                    <div class="table-responsive">
                        <table class="table table-striped items-table">
                            <colgroup>
                                <col />
                                <col width="175px" />
                                <col width="175px" />
                                <col width="175px" />
                                <col width="300px" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{{$t('Item')}}</th>
                                    <th class="text-center">{{$t('Quantity to Return')}}</th>
                                    <th>{{$t('Reason')}}</th>
                                    <th>{{$t('Desired Action')}}</th>
                                    <th v-if="settings.allow_comments">{{$t('Comments')}} & {{$t('Attachments')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item) in order.items" :key="item.id">
                                    <td>
                                        <b-link :to="'/' + item.product_url_key + '/'">{{
                                            item.product_name
                                        }}</b-link><br />
                                        <small class="text-sm text-muted">{{item.product_sku}}</small>
                                    </td>
                                    <td class="text-center quantity">
                                        <div class="qty-input-wrapper" :class="{'is-invalid': item.errors.quantity}">
                                            <span class="number-count">
                                                <input
                                                    class="stock-count form-control"
                                                    :class="{'is-invalid': item.errors.quantity}"
                                                    type="number"
                                                    :id="item.id"
                                                    name="quantity"
                                                    placeholder="0"
                                                    min="0"
                                                    max="100"
                                                    v-model="item.quantity"
                                                    :aria-describedby="'rma-item-quantity-' + item.id + '-feedback'"
                                                    @change="updateItemQuantity(item)"
                                                    />
                                                    
                                            </span>
                                            <span class="number-countarrow">
                                                <div class="count-up">
                                                    <font-awesome-icon
                                                        icon="chevron-up"
                                                        @click="upItemQuantity(item)"
                                                        />
                                                </div>
                                                <div class="count-down">
                                                    <font-awesome-icon
                                                        icon="chevron-down"
                                                        @click="downItemQuantity(item)"
                                                        />
                                                </div>
                                            </span>
                                        </div>
                                        <b-form-invalid-feedback
                                            :id="'rma-item-quantity-' + item.id + '-feedback'"
                                            >{{item.errors.quantity}}
                                        </b-form-invalid-feedback>
                                        <span class="text-muted">Maximaal {{item.quantity_available}}</span>
                                    </td>
                                    <td>
                                        <select v-model="item.reason" class="form-control" :aria-describedby="'rma-item-reason-' + item.id + '-feedback'" :class="{'is-invalid': item.errors.reason}" @change="updateItemReason(item)">
                                            <option value="">{{$t('Select')}}</option>
                                            <option v-for="reason in reasons" :key="reason.id" :value="reason.id">{{reason.label}}</option>
                                        </select>
                                        <b-form-invalid-feedback
                                            :id="'rma-item-reason-' + item.id + '-feedback'"
                                            >{{item.errors.reason}}
                                        </b-form-invalid-feedback>
                                    </td>
                                    <td>
                                        <select v-model="item.action" class="form-control" :aria-describedby="'rma-item-action-' + item.id + '-feedback'" :class="{'is-invalid': item.errors.action}">
                                            <option value="">{{$t('Select')}}</option>
                                            <option v-for="action in item.available_actions" :key="action.id" :value="action.id">{{action.label}}</option>
                                        </select>
                                        <b-form-invalid-feedback
                                            :id="'rma-item-action-' + item.id + '-feedback'"
                                            >{{item.errors.action}}
                                        </b-form-invalid-feedback>
                                    </td>
                                    <td v-if="settings.allow_comments">
                                        <textarea class="form-control mb-2" v-model="item.customer_note"></textarea>                                 
                                        <attachmentUpload v-model="item.attachments" />
                                        
                                        <div class="attachments">
                                            <div class="attachment" v-for="(attachment, index) in item.attachments" :key="index">
                                                <div class="attachment-name">
                                                    {{attachment.name}}.{{attachment.extension}} ({{attachment.hr_size}} MB)
                                                </div>
                                                <div class="attachment-action">
                                                    <span class="text-danger action" @click.prevent="deleteAttachment(item, index)">
                                                        <i class="fa fa-times"></i>
                                                    </span>
                                                </div>
                                            </div>  
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                
                    <div class="form-bottom">
                        <div class="submit-container">
                            <div class="form-group mb-3 text-left">
                                <label for="customer_note">{{$t('Comments')}}</label>
                                <textarea v-model="customer_note" id="customer_note" class="form-control"></textarea>
                            </div>
                            <div class="terms form-check mb-4 text-left" v-if="settings.enable_terms">
                                <input class="form-check-input" aria-describedby="rma-terms-feedback" type="checkbox" v-model="agreedToTerms" :class="{'is-invalid': errors.terms}" id="agreed_terms">
                                <label class="form-check-label" for="agreed_terms">
                                    {{$t('rma_agree_begin')}}
                                    <a href="javascript:void(0)" @click.prevent="$bvModal.show('rma-terms');">{{$t('rma_terms_and_conditions')}}</a>
                                    {{$t('rma_agree_end')}}
                                </label>
                                <b-form-invalid-feedback
                                    id="rma-terms-feedback"
                                    >{{errors.terms}}
                                </b-form-invalid-feedback>
                            </div>
                            
                            <div class="alert alert-danger" v-if="errors.items">
                                {{errors.items}}
                            </div>
                            
                            <div class="actions">
                                <b-button
                                  @click="submitRequest"
                                  :disabled="buttonClicked"
                                  class="btn-lg btn-success"
                                >
                                  {{ $t("rma_submit_request") }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
    </section>
    <section class="rma-page" v-else>
        <returnDetail :returnOrder="returnOrder" />
    </section>
</template>

<script>
    import config from "@config";

    import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
    import attachmentUpload from "@/esf_utrecht_bankxl/core/components/rma/fields/upload.vue";
    import returnDetail from "@/esf_utrecht_bankxl/core/components/rma/Details.vue";
    
    export default {    
        name: "NewRequest",
        components: {
            cmsBlockSimple,
            attachmentUpload,
            returnDetail
        },
        
        props: {
            incrementId: {
                type: String,
                required: true
            }
        },
        
        metaInfo() {
            return {
                meta: [
                    {
                        name: "title",
                        content: "Retour Aanvraag aanmelden",
                    },
                    {
                        name: "keywords",
                        content: "",
                    },
                    {
                        name: "description",
                        content: "Vraag een retour aan voor uw geplaatste bestelling.",
                    },
                ],
                title: "Retour Aanvraag aanmelden",
                link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
            };
        },
        
        watch: {
            incrementId: {
                immediate: true,
                handler() {
                    this.findOrder();
                }
            }
        },
        
        async mounted() {
                        
            await this.fetchIntroCmsBlock();
            
            const bcrumb = {
              current: "Retour Aanvragen",
              routes: [],
            };

            this.$store.commit("breadcrumbs/set", bcrumb);
            
            this.findOrder(); 
        },
        
        data() {
            return {
                order: null,
                customer_note: '',
                agreedToTerms: false,
                buttonClicked: false,
                errors: {},
                reasons: [],
                settings: {
                    allow_comments: false,
                    allow_uploads: false,
                    enable_terms: false,
                    terms_text: ''
                },
                messages: [],
                loading: false,
                rmaIntroBlock: null,
                returnOrder: null
            }
        },
        
        computed: {
            dangerMessages() {
                return this.messages.filter((item) => {
                    return item.type === 'danger';
                });
            },
            successMessages() {
                return this.messages.filter((item) => {
                    return item.type === 'success';
                });
            }
        },
        
        methods: {
            async findOrder() {
                this.loading = true;
                
                let result = await this.$store.dispatch('rma/findUserOrder', {
                    incrementId: this.incrementId
                });
                
                this.loading = false;
                if (typeof result.errors !== 'undefined') {
                    this.showMessages(result.errors);
                    return;
                }
                this.order = { ...result.data.checkReturnAvailable.order };

                this.order.items.forEach((item) => {
                    this.$set(item, 'quantity', 0);
                    this.$set(item, 'reason', '');
                    this.$set(item, 'action', '');
                    this.$set(item, 'available_actions', []);
                    this.$set(item, 'customer_note', '');
                    this.$set(item, 'attachments', []);
                    this.$set(item, 'errors', {});
                });
                this.messages = [];
                this.$set(this.order, 'items', this.order.items);
                
                this.settings = result.data.checkReturnAvailable.settings;
                this.reasons = result.data.checkReturnAvailable.reasons;
            },
            
            showMessages(errors) {
                errors.forEach((error) => {
                    if (
                        typeof error.extensions.category === 'undefined' || (
                        error.extensions.category !== 'graphql-no-such-entity' &&
                        error.extensions.category !== 'graphql-input'
                    )) {
                        this.messages.push({
                            type: 'danger',
                            message: 'rma_request_create_error'
                        });
                        return;
                    }
                    
                    this.messages.push({
                        type: 'danger',
                        message: error.message
                    });
                });
            },
            
            async fetchIntroCmsBlock() {
                await this.$store.dispatch("cmsBlock/multiple", {
                  value: ['rma_new_request_intro'],
                });
                this.rmaIntroBlock = this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
                    "rma_new_request_intro"
                );
            },
            
            upItemQuantity(item) {
                if (item.quantity === item.quantity_available) {
                    return;
                }
                item.quantity += 1;
            },
            
            downItemQuantity(item) {
                if (item.quantity === 0) {
                    return;
                }
                item.quantity -= 1;
            },
            
            updateItemQuantity(item) {
                if (item.quantity < 0) {
                    item.quantity = 0;
                }
                if (item.quantity > item.quantity_available) {
                    item.quantity = item.quantity_available;
                }   
            },
            
            updateItemReason(item) {
                let reason = this.reasons.filter((reason) => {
                    return reason.id === item.reason;
                }).shift();
                
                if (typeof reason === 'undefined') {
                    item.available_actions = [];
                    return;
                }
                
                item.available_actions = reason.actions;
            },
            
            deleteAttachment(item, attachmentIndex) {
                item.attachments.splice(attachmentIndex, 1);
            },
            
            submitRequest() {
                this.messages = [];
                let valid = this.validateRequest();
                
                if (!valid) {
                    this.$forceUpdate();
                    return;
                }
                
                this.loading = false;
                
                let data = this.getRequestData();
                
                this.$store.dispatch('rma/sendUserRequest', {data: data}).then((res) => {
                    if (typeof res.errors !== 'undefined') {
                        this.showMessages(res.errors);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        return;
                    }
                    this.$emit('submitted', res.data.createReturnRequest);
                    this.router.push({path: '/accounts/', query: {name: 'returns', return: res.data.createReturnRequest.number}});
                }).catch((err) => {
                    this.messages.push({
                        type: 'danger',
                        message: 'rma_request_create_error'
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    console.error(err);
                }).then(() => {
                    this.loading = false;
                });
            },
            
            validateRequest() {
                let totalItemsToReturn = 0;
                let isValid = true;
                
                this.errors = {};
                
                this.order.items.forEach((item) => {
                    item.errors = {};
                    
                    if (item.quantity > 0 && (
                        typeof item.reason === 'undefined' ||
                        item.reason === 0 ||
                        item.reason === '' || 
                        item.reason === null
                    )) {
                        isValid = false;
                        item.errors.reason = this.$t('rma_item_reason_required');
                    }
                    
                    if (item.quantity > 0 && (
                        typeof item.action === 'undefined' ||
                        item.action === 0 ||
                        item.action === '' || 
                        item.action === null
                    )) {
                        isValid = false;
                        item.errors.action = this.$t('rma_item_action_required');
                    }
                    
                    if (item.quantity > item.quantity_available) {
                        isValid = false;
                        item.errors.quantity = this.$t('rma_item_qty_over');
                    }
                    
                    totalItemsToReturn += item.quantity;
                });
                
                if (totalItemsToReturn <= 0) {
                    isValid = false;
                    this.errors.items = this.$t('rma_items_qty_zero')
                }
                
                if (this.settings.enable_terms && !this.agreedToTerms) {
                    isValid = false;
                    this.errors.terms = this.$t('rma_terms_must_agree')
                }
                
                return isValid;
            },
            
            getRequestData() {
                let data = {
                    incrementId: this.incrementId,
                    comment: this.customer_note,
                    terms: this.agreedToTerms,
                    items: []
                };
                
                this.order.items.forEach((item) => {
                    if (item.quantity <= 0) {
                        return;
                    }
                    let itemData = {
                        order_item_id: item.id,
                        quantity: item.quantity,
                        reason: item.reason,
                        action: item.action,
                        comment: item.customer_note
                    }
                    
                    if (item.attachments && typeof item.attachments.length === 'number' && item.attachments.length > 0) {
                        itemData.files = [];
                        
                        item.attachments.forEach((attachment) => {
                            let base64 = attachment.content.replace('data:' + attachment.type + ';base64,', '');
                            itemData.files.push({
                                name: attachment.name + '.' + attachment.extension,
                                content: base64
                            });
                        });
                    }
                    
                    data.items.push(itemData);
                });
                
                return data;
            }
        }
    }
    
</script>

<style lang="scss" scoped>

.pro-loader {
  text-align: center;
  padding: 100px 0px 120px 0px;
  .reverse-spinner {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @media screen and (max-width: 560px) {
    padding: 50px 0px;
    .reverse-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .reverse-spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    opacity: 0.7;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}
</style>
